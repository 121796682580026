import React from "react"
import WiczlinoHeader from "../../../components/estates/wiczlino/wiczlinoHeader"
import Description from "../../../components/estates/wiczlino/description"
import Visual from "../../../components/estates/wiczlino/visual"
import Layout from "../../../components/layout"
import Helmet from "react-helmet"
import EstateDisclaimer from "@/components/estates/estateDisclaimer"
import EstateHeader from "@/components/estates/estateHeader"
import JanowoTwoVisual from "@/components/estates/janowotwo/janowoTwoVisual"
import wizImage from "@/images/wiczlinolot.jpg"

function Janowo() {
  return (
    <>
      <Helmet>
        <title>WILLBUD | Osiedle Gdynia Wiczlino</title>
      </Helmet>
      <Layout>
        <EstateHeader title={`Osiedle Gdynia Wiczlino`} place={'Gdynia'} completed noButton />
        <JanowoTwoVisual image={wizImage} alt={`Osiedle Gdynia Wiczlino Wizualizacja`} />

        <WiczlinoHeader />
        <Description />
        <Visual />
        {/* <Search estate={"wiczlino"} /> */}
        {/* <Localization estate={"wiczlino"} /> */}
        {/*<EstatePlan scrollDestination={scrollDestination} />*/}
        {/*<LogBuilding />*/}
        <EstateDisclaimer />
      </Layout>
    </>
  )
}

export default Janowo

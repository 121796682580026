import React, { useState } from "react"
import { VisualContainer } from "./wiczlinoStyle"
import { Col, Container, Row } from "react-bootstrap"
import wiz1 from "../../../images/wiczlino3.jpg"
import wiz2 from "../../../images/wiczlino1.jpg"
import wiz3 from "../../../images/wiczlino2.jpg"
import wiz4 from "../../../images/wiczlino4.jpg"
import wiz5 from "../../../images/wiczlino5.jpg"
import wiz6 from "../../../images/wiczlino6.jpg"
import FsLightbox from "fslightbox-react"
import { injectIntl } from "gatsby-plugin-intl"

const Visual = ({ intl }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    });
  }
  const vis = [
    {
      span: intl.formatMessage({ id: "offer.wiczlino.visual.first.item.title" }),
      desc: intl.formatMessage({ id: "offer.wiczlino.visual.first.item.content" }),
      src: wiz1,
    },
    {
      span: intl.formatMessage({ id: "offer.wiczlino.visual.second.item.title" }),
      desc: intl.formatMessage({ id: "offer.wiczlino.visual.second.item.content" }),
      src: wiz2,
    },
    {
      span: intl.formatMessage({ id: "offer.wiczlino.visual.third.item.title" }),
      desc: intl.formatMessage({ id: "offer.wiczlino.visual.third.item.content" }),
      src: wiz3,
    },
    {
      span: intl.formatMessage({ id: "offer.wiczlino.visual.fourth.item.title" }),
      desc: intl.formatMessage({ id: "offer.wiczlino.visual.fourth.item.content" }),
      src: wiz4,
    },
    {
      span: intl.formatMessage({ id: "offer.wiczlino.visual.fifth.item.title" }),
      desc: intl.formatMessage({ id: "offer.wiczlino.visual.fifth.item.content" }),
      src: wiz5,
    },
    {
      span: intl.formatMessage({ id: "offer.wiczlino.visual.sixth.item.title" }),
      desc: intl.formatMessage({ id: "offer.wiczlino.visual.sixth.item.content" }),
      src: wiz6,
    },
  ]

  const urls = [
    wiz1, wiz2, wiz3, wiz4, wiz5, wiz6
  ]
  return (
    <VisualContainer className="visual">
      <Container className="no-padding">
        <Row>
          <Col className="no-padding wiz-title" xs="12">
            <h1>{intl.formatMessage({ id: "offer.wiczlino.visual.title" })}</h1>
          </Col>
          {vis.map((item, i) => (
            <Col
              key={i}
              className="no-padding wiz-container"
              xs="12"
              sm="12"
              md="6"
              lg="6"
            >
              <div className="wiz-img">
                <img
                  onClick={() => {
                    openLightboxOnSlide(i + 1)
                  }}
                  src={item.src}
                  alt={""}
                />
              </div>
              <p>
                <span>{item.span}</span> {item.desc} {item.desc1 ? <div style={{ display: "inline-block" }}>m<sup>2</sup>{item.desc1}</div> : ""}
              </p>
            </Col>
          ))}
          <FsLightbox
            toggler={lightboxController.toggler}
            sources={urls}
            slide={lightboxController.slide}
          />
        </Row>
      </Container>
      <div className="flats"></div>
    </VisualContainer>
  )
}
export default injectIntl(Visual)

import React, { Fragment } from "react"
import EstateStatus from "../estateStatus"
import EstateTitle from "../estateTitle"
import EstateNav from "../estateNav"
import { HeaderContainer, PlanVisual } from "./wiczlinoStyle"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "gatsby-plugin-intl"

const WiczlinoHeader = ({ currentLocation, intl }) => {
  const noPadding = { margin: "auto" }
  const desc =
    intl.formatMessage({ id: "offer.wiczlino.content" })
  return (
    <Fragment>
      <HeaderContainer>
        <Container style={noPadding}>
          <Row>
            <Col
              className="content-wrapper"
              style={noPadding}
              xs="12"
              sm="12"
              md="6"
              lg="4"
            >
              <EstateTitle
                subTitleBold={
                  intl.formatMessage({ id: "offer.wiczlino.content.bold" })
                }
                subtitle={desc}
              />
            </Col>
            <Col className="vector-pic" xs="12" sm="12" md="6" lg="4"></Col>
            <Col className="header-nav" xs="0" sm="0" md="6" lg="4">
              <EstateNav completed={true} />
            </Col>
            <Col className="desktop-info" xs="12">
              <div>
                10 <span>{intl.formatMessage({ id: "offer.wiczlino.info.buildings.name" })}</span>
              </div>
              <div>
                20 <span>{intl.formatMessage({ id: "offer.wiczlino.info.flats.name" })}</span>
              </div>
              <div>
                134 m<sup>2</sup>
                <span>{intl.formatMessage({ id: "offer.wiczlino.info.space.name" })}</span>
              </div>
              <div>
                402 - 628m<sup>2</sup> <span>{intl.formatMessage({ id: "offer.wiczlino.info.sold.name" })}</span>
              </div>
              <div>
              </div>
            </Col>
          </Row>
        </Container>
      </HeaderContainer>

    </Fragment>
  )
}

export default injectIntl(WiczlinoHeader);

import styled from "styled-components"
import home from "../../../images/home21.png"
import vector from "../../../images/vector-map-icon.png"
import vectorPic from "../../../images/vector-pic.png"
import plan from "../../../images/wiczlinolot.jpg"

export const HeaderContainer = styled.div`
  .content-wrapper {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    padding: 0;
  }
  .content-status {
    padding: 0;
  }
  @media only screen and (min-width: 768px) {
    margin-bottom: 45px;
  }
  /* @media only screen and (min-width: 1440px) {
    margin: 0 90px 0;
  } */
  .desktop-info {
    margin: 45px 0 0;
    padding: 35px 0 0;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #94c835;
    align-items: center;
    justify-content: space-around;
    gap: 17px;
    div {
      //margin-right: 35px;
      text-align: center;
      font-size: 36px;
      span {
        font-size: 24px;
        @media only screen and (max-width: 991px) {
          font-size: 16px;
        }
        @media only screen and (max-width: 1200px) {
          font-size: 20px;
        }
      }
      @media only screen and (max-width: 991px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 1200px) {
        font-size: 20px;
      }
    }
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
  .vector-pic {
    background-image: url(${vectorPic});
    background-repeat: no-repeat;
    background-position: center bottom;
    padding: 0 30px;
    @media only screen and (max-width: 1199px) {
      display: none;
    }
  }
  .header-nav {
    padding: 0;
  }
`

export const PlanVisual = styled.div`
  .plan-wiz {
    height: 560px;
    background-image: url(${plan});
    background-size: cover;
    background-position: center;
  }
`

export const DescriptionStyled = styled.div`
  @media only screen and (min-width: 1440px) {
    margin: 0 90px 0;
  }

  .title {
    padding: 0;
    margin: 100px 0 40px;
    h1 {
      font-size: 37px;
      margin: 0;
    }
  }
  .no-padding {
    /* padding: 0; */
    margin: auto;
  }

  .content {
    padding: 0 30px;
    font-size: 20px;
    font-weight: 200;
    margin-bottom: 20px;
    p:nth-of-type(4) {
    }
    .paragraph {
      padding: 0;
    }
    .paragraph:nth-of-type(2) p {
      font-weight: 500;
    }
    .paragraph:nth-of-type(5) {
      margin-bottom: 40px;
    }
    .icons-section {
      @media only screen and (min-width: 768px) {
        margin-bottom: 100px;
      }
      > div {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        h4 {
          font-size: 20px;
          font-weight: 500;
          margin: 10px 6px;
        }
        div {
          display: inline-block;
          min-width: 48px;
          height: 48px;
          margin: 10px 16px 10px 5px;
          border: 2px solid #94c835;
          border-radius: 8px;
          position: relative;
          ::after {
            position: absolute;
            height: 40px;
            width: 15px;
            background-color: transparent;
            content: "";
            left: 60%;
            top: 10px;
            transform: translate(-50%, -50%) rotate(40deg);
            border-right: 2px solid #94c835;
            border-bottom: 2px solid #94c835;
          }
          ::before {
            position: absolute;
            border-radius: 50%;
            top: -8px;
            right: -16px;
            transform: translate(-50%);
            height: 16px;
            width: 16px;
            content: "";
            background-color: white;
          }
        }
      }
    }
  }
  .vector-image-container {
    height: 100%;
  }
  .vector-image {
    margin: 0;
    height: 100%;
    div {
      padding: 0;
      width: 100%;
      right: -45px;
      min-height: 360px;
      margin: 30px 0 0;
      background-image: url(${home});
      background-repeat: no-repeat;
      background-position: right;
      @media only screen and (max-width: 1199px) {
        background-position: left center;
      }
    }
  }
`

export const LogBuildingStyled = styled.div`
  @media only screen and (min-width: 768px) {
    margin: 0 0 100px;
  }
  .no-padding {
    margin: 0;
    padding: 0;
  }
  .custom-padding {
  padding: 90px 0 35px 0;
  }
  button {
    width: 100%;
  }
  padding: 100px 30px 45px;
  div:nth-child(2) {
    border: none !important;
  }
  .heading {
    padding-bottom: 30px;
    font-size: 20px;
    h1 {
      padding-bottom: 32px;
      font-size: 37px;
    }
    p {
      font-weight: 200;
      span {
        font-weight: 500;
      }
    }
  }
  .buttons-timeline {
    padding-top: 100px;
    max-width: 260px;
    button {
      margin: 0 0 10px;
    }
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }
`
export const LogItemStyled = styled.div`
.timeline-container {
  position: relative;
}
.first-item {
  display: none;
}
.second-item {
  ::after {
    display: none !important;
  }
}
.timeline {
  width: 2px;
  height: 100%;
  background-color: #94c835;
  position: absolute;
  @media only screen and (max-width: 768px) {
    left: 20%;
  }
  left: 50%;
  top: 25px;
  transform: translateX(-50%);
  ::before {
    background-color: #94c835;
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 4px solid white;
    transform: translate(-50%, -50%)
  }
  ::after {
    background-color: #94c835;
    content: "";
    position: absolute;
    left: 50%;
    bottom: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 4px solid white;
    transform: translateX(-50%)
  }
}
  .no-padding {
    margin: 0;
    padding: 0;
  }
  .month {
    padding: 0;
    font-size: 24px;
    p {
      margin: 0;
      font-weight: 500;
      padding: 10px 0;
      height: 50px;
      font-size: 20px;
    }
  }
  .log-image:nth-child(1) {
    margin-bottom: 8px;
    margin-right: 8px;
  }
  .log-image:nth-child(2) {
    margin-bottom: 8px;
    margin-left: 8px;
  }
  .log-image:nth-child(3) {
    margin-top: 8px;
    margin-right: 8px;
  }
  .log-image:nth-child(4) {
    margin-top: 8px;
    margin-left: 8px;
  }
  /* .log-image {
    padding: 0;
    overflow: hidden;
    min-height: 100px;
    min-width: 100px;
    position: relative;
    display: flex;
    justify-content: space-around;
    background-image: url(${props => props.pic});

    img {
      width: auto;
      height: 100%;
      position: absolute;
    }
  } */
`
export const VisualContainer = styled.div`
  background-color: #f8f8f8;
  position: relative;
  padding: 100px 0 0;
  /* @media only screen and (min-width: 1440px) {
    padding: 60px 120px 0;
  } */
  .no-padding {
    margin: auto;
    /* padding: 0; */
  }
  h1 {
    margin-bottom: 40px;
  }
  .wiz-title {
    margin: 0;
    padding: 0;
  }
  .wiz-container {
    min-height: 365px;
    margin: 0;
    padding: 0;
    p {
      padding: 24px 40px 50px 0;
      font-weight: 200;
      font-size: 20px;

      span {
        font-weight: 500;
        display: block;
        margin-bottom: 10px;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .wiz-container:nth-child(even) {
      p {
        padding-right: 40px;
      }
      .wiz-img {
        img {
          margin-right: 40px;
        }
      }
    }
    .wiz-container:nth-child(odd) {
      p {
        padding: 24px 20px 50px 40px;
      }
      .wiz-img {
        img {
          left: 0;
          margin-left: 40px;
        }
      }
    }
  }
  .wiz-img {
    height: 315px;
    width: 100%;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      right: 0;
      width: auto;
      height: 100%;
      @media only screen and (min-width: 600px) {
        width: 100%;
        height: initial;
      }
      @media only screen and (min-width: 768px) {
        width: auto;
        height: 100%;
      }
      @media only screen and (min-width: 1200px) {
        width: 100%;
        height: auto;
      }
      @media only screen and (min-width: 1440px) {
        width: 100%;
        height: auto;
        top: -60px;
      }
    }
  }
`

export const LocalizationContainer = styled.div`
  .no-padding {
    margin: 0;
    padding: 0;
  }
  background-color: #f8f8f8;
  .localization-title {
    padding: 100px 30px 50px;
    h1 {
      margin: 0;
      font-size: 37px;
    }
  }
  .google-map-row {
    min-height: 375px;
    position: relative;
    @media only screen and (min-width: 1200px) {
      min-height: 600px;
    }
  }
  .localization-content {
    padding: 40px 30px 35px;
    font-size: 20px;
    font-weight: 200;
    background-color: #f8f8f8;
    .places-title {
      margin: auto;
    }
    .places-offset {
      display: none;
      @media only screen and (min-width: 1200px) {
        display: flex;
      }
    }
    span {
      font-weight: 500;
    }
  }
  .places-info {
    span {
      font-size: 37px;
      font-weight: 500;
    }
    div {
      font-size: 20px;
      font-weight: 200;
    }
    div:nth-child(1) {
      padding: 40px 0 15px;
      border-bottom: solid 1px #94c835;
    }
    div:nth-child(2) {
      padding: 15px 0;
      border-bottom: solid 1px #94c835;
    }
    div:nth-child(3) {
      padding: 15px 0;
    }
  }
  .localization-vector {
    background-color: #f8f8f8;
    min-height: 160px;
    @media only screen and (min-width: 1200px) {
      display: none;
    }
    .vector-background {
      min-height: 160px;
      width: 100%;
      background-image: url(${vector});
    }
  }
`
export const EstatePlanContainer = styled.div`
position: relative;
.return {
  position: absolute;
  right: 0;
  top: 0;
}
  /* background-color: #92c836; */
  color: white;
  .no-padding {
    margin: 0;
    padding: 0;
  }
  .plan-title {
    padding: 100px 0 20px;
  }
  .plan-content {
    font-size: 20px;
    font-weight: 200;
    p{
    margin: 0;

    }
    span {
      font-weight: 400;
    }
  }
  .plan-container {
    margin: 100px auto 0;
    width: 100%;
    height: 100%;
    min-height: 250px;
    position: relative;
    svg {
      path {
        :hover  {
        fill: #94c835;
        opacity: .5
        }
      }
      path {
      pointer-events: all;
      cursor: pointer;
      }
    }
    path {
      z-index: 1000;
    }
    .visible-pic {
      height: auto;
      width: 100%;
      opacity: 1;
    }
    .hidden-pic, svg {
      position: absolute;
      width: 100%;
      height: auto;
      left: 0;
      top: 0;
    }
    .hidden-pic{
      opacity: 0;
    }

    /* > div {
      margin: auto;
    width: 100% !important;
    height: 100% !important;    } */
    @media only screen and (min-width: 1200px) {
        text-align: center;
      }
  }
  .mobile-text {
    @media only screen and (min-width: 767px) {
        display:none;
      }
  }
  .desktop-text {
    @media only screen and (max-width: 768px) {
        display:none;
      }
  }

`

export const Image = styled.div`
  padding: 0;
  overflow: hidden;
  min-height: 100px;
  min-width: 100px;
  position: relative;
  display: flex;
  justify-content: space-around;
  background-image: url(${props => props.src});
  background-size: cover;
  transition: all linear 0.4s;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`


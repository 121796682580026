import React from "react"
import { DescriptionStyled } from "./wiczlinoStyle"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "gatsby-plugin-intl"

const Description = ({intl}) => {
  return (
    <DescriptionStyled>
      <div className="content description" style={{ padding: 0 }}>
        <Container className="no-padding">
          <Row>
            <Col
              className="no-padding"
              style={{ padding: 0 }}
              xs="12"
              sm="12"
              md="6"
            >
              <Container style={{ padding: 0, margin: 0 }} fluid>
                <Row>
                  <Col className="title" xs="12">
                    <h1>{intl.formatMessage({ id: "offer.janowo.description.title" })}</h1>
                  </Col>
                  <Col xs={12} className={"paragraph"}>
                    <p>{intl.formatMessage({ id: "offer.wiczlino.description.title" })}</p>
                  </Col>
                  <Col xs={12} className={"paragraph"}>
                    <p>
                      {intl.formatMessage({ id: "offer.wiczlino.description.content.first" })}
                    </p>
                  </Col>
                  <Col xs={12} className={"paragraph"}>
                    <p>
                      {intl.formatMessage({ id: "offer.wiczlino.description.content.second" })}
                    </p>
                  </Col>
                  <Col xs={12} className={"paragraph"}>
                    <p>
                      {intl.formatMessage({ id: "offer.wiczlino.description.content.third" })}
                    </p>
                  </Col>
                </Row>
                <Row className="icons-section">
                  <Col xs="12" sm="12" md="12" xl="4">
                    <div></div>
                    <h4> {intl.formatMessage({ id: "offer.wiczlino.description.info.first" })}</h4>
                  </Col>
                  <Col xs="12" sm="12" md="12" xl="4">
                    <div></div>
                    <h4>{intl.formatMessage({ id: "offer.wiczlino.description.info.second" })}</h4>
                  </Col>
                  <Col xs="12" sm="12" md="12" xl="4">
                    <div></div>
                    <h4>{intl.formatMessage({ id: "offer.wiczlino.description.info.third" })}</h4>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col className="no-padding" xs="12" sm="12" md="6">
              <Container
                style={{ padding: 0 }}
                className="vector-image-container"
                fluid
              >
                <Row className="vector-image">
                  <Col xs="12"></Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </DescriptionStyled>
  )
}

export default injectIntl(Description)
